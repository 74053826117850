var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialogs.addPayment,"width":"500","overlay-opacity":0.9,"scrollable":""},on:{"click:outside":_vm.closeDialog,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDialog.apply(null, arguments)}}},[_c('v-card',[_c('v-toolbar',{staticClass:"pr-0",attrs:{"height":48,"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(" Добавить платеж "+_vm._s(_vm.users.forDialog.user.username)+" ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":12}},[_c('v-text-field',{staticClass:"mr-4",attrs:{"label":"Сумма","dense":"","outlined":"","hide-details":"","type":"number"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}},_vm._l((['RUB', 'USD']),function(item){return _c('v-btn',{key:("anty-admin-panel-add-payment-" + item),attrs:{"value":item,"small":"","text":"","color":"primary"}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1),_c('v-col',{attrs:{"cols":12}},[_c('v-select',{staticClass:"users-dialog-add-payment-reason-combobox",attrs:{"items":_vm.reasonList,"label":"Выберите причину","multiple":"","small-chips":"","deletable-chips":"","clearable":"","outlined":"","dense":"","hide-details":"","error":_vm.reasonError,"menu-props":{
              maxHeight: '300px',
              offsetY: true,
              nudgeTop: 350,
            }},on:{"change":_vm.handleReasonChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._b({on:{"click":function($event){return _vm.handleSelectReason(item)}}},'v-list-item',attrs,false),[_c('v-simple-checkbox',{attrs:{"color":"primary","value":_vm.reasonIsSelected(item)},on:{"click":function($event){return _vm.handleSelectReason(item)}}}),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")]),(item.subText)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.subText)+" ")]):_vm._e()],1)],1)]}}]),model:{value:(_vm.selectedReasons),callback:function ($$v) {_vm.selectedReasons=$$v},expression:"selectedReasons"}})],1),_c('v-col',{attrs:{"cols":12}},[_c('v-textarea',{attrs:{"label":"Дополнительные комментарии","outlined":"","dense":"","hide-details":"","auto-grow":"","rows":"1"},model:{value:(_vm.additionalComments),callback:function ($$v) {_vm.additionalComments=$$v},expression:"additionalComments"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":"","color":"grey"},on:{"click":_vm.closeDialog}},[_vm._v(" Закрыть ")]),_c('v-btn',{attrs:{"small":"","text":"","color":"primary","loading":_vm.loading},on:{"click":_vm.addPayment}},[_vm._v(" Добавить ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }