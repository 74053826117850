// eslint-disable-next-line import/prefer-default-export
export const reasonList = [
  { text: 'Оплата в крипте', value: 'cryptoPayment' },
  { text: 'Оплата банковской картой', value: 'cardPayment' },
  { text: 'Выставление счета на оплату со скидкой', value: 'discount' },
  { text: 'Апгрейд/Даунгрейд тарифа', value: 'upgradeDowngradeTariff' },
  { text: 'Проблемы с оплатой в крипте', value: 'problemsWithCryptoPayment', subText: 'Недоплата/переплата' },
  { text: 'Возврат средств', value: 'refund', subText: 'Укажите причину в комментариях' },
  { text: 'Заморозка тарифа', value: 'tariffFreeze' },
  { text: 'Партнер', value: 'partner' },
  { text: 'Студент обучающего курса', value: 'courseStudent' },
  { text: 'Партнёрский сервис', value: 'partnerService' },
  { text: 'Доступ за интеграции', value: 'integrationAccess' },
  { text: 'Доступ сотруднику', value: 'employeeAccess' },
  { text: 'Доступ клиентам при инцидентах/компенсациях', value: 'incidentAccess' },
  { text: 'Доступ до оплаты', value: 'accessBeforePayment' },
  { text: 'Доступ для переноса профилей', value: 'transferAccess' },
  { text: 'Доступ для тестирования фичи', value: 'testAccess' },
  { text: 'Победитель розыгрыша', value: 'prize' },
  { text: 'Черная Пятница 2024', value: 'blackFriday' },
  { text: 'Новогодняя акция 2024', value: 'newYear' },
];
