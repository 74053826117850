<template>
  <v-dialog
    :value="dialogs.addPayment"
    width="500"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Добавить платеж {{ users.forDialog.user.username }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            :cols="12"
            class="d-flex align-center"
          >
            <!-- Сумма -->
            <v-text-field
              v-model="amount"
              label="Сумма"
              dense
              outlined
              hide-details
              type="number"
              class="mr-4"
            />

            <!-- ВАЛЮТА -->
            <v-btn-toggle
              v-model="currency"
              mandatory
            >
              <v-btn
                v-for="item in ['RUB', 'USD']"
                :key="`anty-admin-panel-add-payment-${item}`"
                :value="item"
                small
                text
                color="primary"
              >
                {{ item }}
              </v-btn>
            </v-btn-toggle>
          </v-col>

          <!-- ПРИЧИНА -->
          <v-col :cols="12">
            <v-select
              v-model="selectedReasons"
              :items="reasonList"
              label="Выберите причину"
              multiple
              small-chips
              deletable-chips
              clearable
              outlined
              dense
              hide-details
              :error="reasonError"
              @change="handleReasonChange"
              :menu-props="{
                maxHeight: '300px',
                offsetY: true,
                nudgeTop: 350,
              }"
              class="users-dialog-add-payment-reason-combobox"
            >
              <template #item="{ item, attrs }">
                <v-list-item
                  v-bind="attrs"
                  @click="handleSelectReason(item)"
                >
                  <v-simple-checkbox
                    color="primary"
                    :value="reasonIsSelected(item)"
                    @click="handleSelectReason(item)"
                  />
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="item.subText">
                      {{ item.subText }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>

          <!-- ДОПОЛНИТЕЛЬНЫЕ КОММЕНТАРИИ -->
          <v-col :cols="12">
            <v-textarea
              v-model="additionalComments"
              label="Дополнительные комментарии"
              outlined
              dense
              hide-details
              auto-grow
              rows="1"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
          :loading="loading"
          @click="addPayment"
        >
          Добавить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { reasonList } from '@/constants/tariffPlanReson/reasonList';

export default {
  name: 'UsersDialogAddPayment',

  data() {
    return {
      amount: 0,
      currency: 'RUB',
      loading: false,
      reasonList,
      selectedReasons: [],
      additionalComments: '',
      reasonError: false,
    };
  },

  computed: {
    ...mapGetters({
      dialogs: 'users/dialogs',
      users: 'users/users',
    }),
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'addPayment');
    },

    handleReasonChange() {
      if (this.selectedReasons.length) {
        this.reasonError = false;
      }
    },

    reasonIsSelected(item) {
      return this.selectedReasons.some((reason) => reason.value === item.value);
    },

    handleSelectReason(value) {
      const foundReason = this.selectedReasons.find((reason) => reason.value === value.value);

      if (foundReason) {
        this.selectedReasons = this.selectedReasons.filter((reason) => reason.value !== value.value);
      } else {
        this.selectedReasons.push(value);
      }
    },

    async addPayment() {
      this.reasonError = false;

      if (!this.selectedReasons.length) {
        this.reasonError = true;
        return;
      }

      try {
        this.loading = true;
        const reasonOptions = this.selectedReasons.map((reason) => reason.value);

        const response = await this.api.post('/admin/subscription/payment', {
          teamId: this.users.forDialog.user.teamId,
          paymentAmount: this.amount,
          paymentCurrency: this.currency,
          reasonOptions,
          additionalComments: this.additionalComments,
        });

        if (response.status === 200) {
          this.closeDialog();
          this.$store.dispatch('users/loadCurrentPage');
          this.$store.dispatch('main/alert', { color: 'success', message: 'Платеж успешно добавлен' });
        }
      } catch (error) {
        //
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>
<style lang="scss">
.v-input__control .v-select__selections .v-chip {
  margin: 6px 6px 0;
}

.users-dialog-add-payment-reason-combobox .v-input__control .v-select__selections {
  margin: 2px 0;
  max-height: 150px;
  overflow-y: auto;
}

.users-dialog-add-payment-reason-combobox.v-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  padding: 0 60px 0 0;
  overflow: auto;
  overflow-x: hidden;
  min-width: 100%;
  max-height: 170px;
  margin: 0;
  height: 100%;

  &::-webkit-scrollbar {
    cursor: pointer;
  }
}

.users-dialog-add-payment-reason-combobox.v-text-field.v-text-field--enclosed .v-input__control .v-input__slot {
  padding: 0 4px 0 12px;
}

.users-dialog-add-payment-reason-combobox.v-input .v-input__control .v-input__slot .v-select__slot {
  padding: 2px 0;
}

.users-dialog-add-payment-reason-combobox .v-select__slot .v-input__append-inner:nth-child(3) {
  right: 35px;
}

.users-dialog-add-payment-reason-combobox .v-select__slot .v-input__append-inner:nth-child(4) {
  right: 8px;
}

.users-dialog-add-payment-reason-combobox .v-input__append-inner {
  position: absolute;
  cursor: pointer;
}
</style>
