<template>
  <v-dialog
    :value="dialogs.changePlan"
    width="600"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Сменить тариф пользователю {{ username }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-row>
          <!-- ТАРИФ -->
          <v-col :cols="12">
            <v-btn-toggle
              v-model="plan"
              mandatory
              @change="planChanged"
            >
              <v-btn
                v-for="item in ['free', 'base', 'team', 'enterprise']"
                :key="`anty-admin-panel-change-plan-${item}`"
                :value="item"
                small
                text
                color="primary"
              >
                {{ item }}
              </v-btn>
            </v-btn-toggle>
            <!-- <v-text-field
              label="Тариф"
              dense
              outlined
              hide-details
              :spellcheck="false"
            /> -->
          </v-col>

          <!-- КОЛИЧЕСТВО ПОЛЬЗОВАТЕЛЕЙ -->
          <v-col :cols="12">
            <div class="mb-4">
              Основная информация
            </div>
            <v-text-field
              v-model="usersLimit"
              label="Количество пользователей"
              dense
              outlined
              :spellcheck="false"
              :disabled="['free'].includes(plan)"
              :persistent-hint="true"
              :hint="hintForUsersInput"
            />
          </v-col>

          <!-- КОЛИЧЕСТВО ПРОФИЛЕЙ -->
          <v-col :cols="12">
            <v-text-field
              v-model="browserProfilesLimit"
              label="Количество профилей"
              dense
              outlined
              :spellcheck="false"
              :disabled="['team', 'base'].includes(plan)"
              type="number"
              :min="plan === 'free' ? 10 : plan === 'enterprise' ? 1000 : 10"
              :step="plan === 'free' ? 10 : plan === 'enterprise' ? 1000 : 10"
              :max="plan === 'free' ? 60 : 100000"
              :persistent-hint="true"
              :hint="hintForProfilesInput"
            />
          </v-col>

          <!-- ДОБАВИТЬ ДНЕЙ -->
          <v-col :cols="12">
            <v-text-field
              v-model="daysToAdd"
              label="Добавить дней"
              dense
              outlined
              hide-details
              :spellcheck="false"
              type="number"
            />
          </v-col>

          <!-- ДОБАВИТЬ ПЛАТЕЖ -->
          <v-col
            :cols="12"
            class="d-flex align-center flex-wrap"
          >
            <div
              style="width: 100%;"
              class="mb-4"
            >
              Добавить платеж
            </div>
            <!-- Сумма -->
            <v-text-field
              v-model="amount"
              label="Сумма"
              dense
              outlined
              hide-details
              type="number"
              class="mr-4"
            />

            <!-- ВАЛЮТА -->
            <v-btn-toggle
              v-model="currency"
              mandatory
            >
              <v-btn
                v-for="item in ['RUB', 'USD']"
                :key="`anty-admin-panel-add-payment-${item}`"
                :value="item"
                small
                text
                color="primary"
              >
                {{ item }}
              </v-btn>
            </v-btn-toggle>
          </v-col>

          <!-- ПРИЧИНА -->
          <v-col :cols="12">
            <v-select
              v-model="selectedReasons"
              :items="reasonList"
              label="Выберите причину"
              multiple
              small-chips
              deletable-chips
              clearable
              outlined
              dense
              hide-details
              :menu-props="{
                offsetY: true,
                nudgeTop: 350,
                maxHeight: '300px'
              }"
              :error="reasonError"
              @change="handleReasonChange"
              class="users-dialog-change-plan-reason-combobox"
            >
              <template #item="{ item, attrs }">
                <v-list-item
                  v-bind="attrs"
                  @click="handleSelectReason(item)"
                >
                  <v-simple-checkbox
                    color="primary"
                    :value="reasonIsSelected(item)"
                    @click="handleSelectReason(item)"
                  />
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="item.subText">
                      {{ item.subText }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>

          <!-- ДОПОЛНИТЕЛЬНЫЕ КОММЕНТАРИИ -->
          <v-col :cols="12">
            <v-textarea
              v-model="additionalComments"
              label="Дополнительные комментарии"
              outlined
              dense
              hide-details
              auto-grow
              rows="1"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
          :loading="loading"
          @click="changeLicense"
        >
          Сменить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { reasonList } from '@/constants/tariffPlanReson/reasonList';

export default {
  name: 'UsersDialogChangePlan',

  data() {
    return {
      plan: '',
      usersLimit: '',
      browserProfilesLimit: '',
      subscriptionExpiration: '',
      loading: false,
      daysToAdd: 0,
      amount: 0,
      currency: 'RUB',
      reasonList,
      selectedReasons: [],
      additionalComments: '',
      reasonError: false,
    };
  },

  computed: {
    ...mapGetters({
      users: 'users/users',
      dialogs: 'users/dialogs',
    }),

    hintForUsersInput() {
      return `Начальное значение - ${this.users.forDialog.user.usersLimit}`;
    },
    hintForProfilesInput() {
      return `Начальное значение - ${this.users.forDialog.user.browserProfilesLimit}`;
    },
  },

  created() {
    this.username = this.users.forDialog.user.username;
    this.plan = this.users.forDialog.user.plan;
    this.usersLimit = this.users.forDialog.user.usersLimit;
    this.browserProfilesLimit = this.users.forDialog.user.browserProfilesLimit;
    this.subscriptionExpiration = this.users.forDialog.user.subscriptionExpiration;
  },

  beforeDestroy() {
    this.$store.dispatch('users/clearForDialog');
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('users/closeDialog', 'changePlan');
    },

    handleReasonChange() {
      if (this.selectedReasons.length) {
        this.reasonError = false;
      }
    },

    reasonIsSelected(item) {
      return this.selectedReasons.some((reason) => reason.value === item.value);
    },

    handleSelectReason(value) {
      const foundReason = this.selectedReasons.find((reason) => reason.value === value.value);

      if (foundReason) {
        this.selectedReasons = this.selectedReasons.filter((reason) => reason.value !== value.value);
      } else {
        this.selectedReasons.push(value);
      }
    },

    planChanged(value) {
      if (value === 'free') {
        this.usersLimit = 1;
        this.browserProfilesLimit = 10;
      }
      if (value === 'base') {
        this.browserProfilesLimit = 100;
      }
      if (value === 'team') {
        this.browserProfilesLimit = 300;
      }
      if (value === 'enterprise') {
        this.browserProfilesLimit = 1000;
      }
    },

    async changeLicense() {
      this.reasonError = false;

      if (!this.selectedReasons.length) {
        this.reasonError = true;
        return;
      }

      try {
        this.loading = true;
        const reasonOptions = this.selectedReasons.map((reason) => reason.value);
        const data = {
          teamId: this.users.forDialog.user.teamId,
          plan: this.plan,
          usersLimit: this.usersLimit,
          browserProfilesLimit: this.browserProfilesLimit,
          subscriptionExpiration: this.subscriptionExpiration,
          daysToAdd: this.daysToAdd,
          paymentAmount: this.amount,
          paymentCurrency: this.currency,
          reasonOptions,
          additionalComments: this.additionalComments,
        };

        const response = await this.api.patch('/admin/subscription', data);

        if (response.status === 200) {
          this.closeDialog();
          this.$store.dispatch('users/loadCurrentPage');
          this.$store.dispatch('main/alert', { color: 'success', message: 'Тариф успешно изменен' });
        }
      } catch (error) {
        //
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>
<style lang="scss">
.v-input__control .v-select__selections .v-chip {
  margin: 6px 6px 0;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.transparent-scrollbar ::-webkit-scrollbar {
  background-color: transparent !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.theme--dark ::-webkit-scrollbar-thumb {
  background-color: #474747;
}

.users-dialog-change-plan-reason-combobox.v-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  padding: 0 60px 0 0;
  overflow: auto;
  overflow-x: hidden;
  min-width: 100%;
  max-height: 170px;
  margin: 0;
  height: 100%;

  &::-webkit-scrollbar {
    cursor: pointer;
  }
}

.users-dialog-change-plan-reason-combobox.v-text-field.v-text-field--enclosed .v-input__control .v-input__slot {
  padding: 0 4px 0 12px;
}

.users-dialog-change-plan-reason-combobox.v-input .v-input__control .v-input__slot .v-select__slot {
  padding: 2px 0;
}

.users-dialog-change-plan-reason-combobox .v-select__slot .v-input__append-inner:nth-child(3) {
  right: 35px;
}

.users-dialog-change-plan-reason-combobox .v-select__slot .v-input__append-inner:nth-child(4) {
  right: 8px;
}

.users-dialog-change-plan-reason-combobox .v-input__append-inner {
  position: absolute;
  cursor: pointer;
}
</style>
